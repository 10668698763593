import { graphql, HttpResponse } from 'msw';
import { GetReportConfigQuery, ReportTypeEnum } from '@revelio/data-access';

export const reportConfigHandler = (
  customReportConfig?: GetReportConfigQuery['reports_validation']
) => {
  return graphql.query('GetReportConfig', () => {
    return HttpResponse.json({
      data: {
        reports_validation: customReportConfig || [
          {
            report_validation: {
              id: 3,
              report_type: 'MarketTightness',
              label: 'Market Conditions',
              description:
                'The Market Conditions Report offers a comprehensive overview of the talent landscape, evaluating factors such as ease of hiring, pay and wage trends, demographics, and the competitive landscape within specific locations',
              sample_link:
                'https://deliverables.reveliolabs.com/report/?id=sample_market_tightness',
              main_entity: [
                {
                  selection_list: ['metro_area'],
                  limit: 1,
                  required: true,
                  label: 'Focus Metro Area',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
              ],
              comparison_entities: [
                {
                  selection_list: ['metro_area'],
                  limit: 5,
                  required: true,
                  label: 'Metro Area Comparison Group',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
              ],
              filters: [
                {
                  selection_list: ['role_k150'],
                  limit: 1,
                  required: true,
                  label: 'Occupation',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
              ],
              __typename: 'ReportValidation',
            },
            __typename: 'ReportsValidationResponse',
          },
          {
            report_validation: {
              id: 4,
              report_type: 'JobComparison',
              label: 'Job Comparison',
              description:
                'A holistic comparison of two job opportunities, offering recruiters and candidates crucial insights into the content of the roles, required skills, and both short- and long-term benefits of each position.',
              sample_link:
                'https://deliverables.reveliolabs.com/report/?id=sample_job_comparison',
              main_entity: [
                {
                  selection_list: ['role_k150'],
                  limit: 1,
                  required: true,
                  label: 'Current Role',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
                {
                  selection_list: ['metro_area'],
                  limit: 1,
                  required: true,
                  label: 'Current Metro Area',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
                {
                  selection_list: ['company'],
                  limit: 1,
                  required: true,
                  label: 'Current Company',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
                {
                  selection_list: ['seniority'],
                  limit: 1,
                  required: true,
                  label: 'Current Seniority',
                  description:
                    'Please do not select the Senior Executive level',
                  __typename: 'ReportValidationEntity',
                },
              ],
              comparison_entities: [
                {
                  selection_list: ['role_k150'],
                  limit: 1,
                  required: true,
                  label: 'Target Role',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
                {
                  selection_list: ['metro_area'],
                  limit: 1,
                  required: true,
                  label: 'Target Metro Area',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
                {
                  selection_list: ['company'],
                  limit: 1,
                  required: true,
                  label: 'Target Company',
                  description:
                    'This must be a different company from the main Company selected',
                  __typename: 'ReportValidationEntity',
                },
                {
                  selection_list: ['seniority'],
                  limit: 1,
                  required: true,
                  label: 'Target Seniority',
                  description:
                    'Please do not select the Senior Executive level',
                  __typename: 'ReportValidationEntity',
                },
              ],
              filters: [],
              __typename: 'ReportValidation',
            },
            __typename: 'ReportsValidationResponse',
          },
          {
            report_validation: {
              id: 8,
              report_type: ReportTypeEnum.MarketIntelligence,
              label: 'Market Intelligence',
              description:
                'The Market Intelligence Report provides a quick overview of the competitive landscape for talent in a particular role, industry, and geography, allowing decision makers to gain insights and get a leg up on the hiring needs.',
              sample_link:
                'https://deliverables.reveliolabs.com/report/?id=sample_market_intelligence',
              main_entity: [
                {
                  selection_list: ['metro_area'],
                  limit: 1,
                  required: true,
                  label: 'Metro Area',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
              ],
              comparison_entities: [],
              filters: [
                {
                  selection_list: ['industry'],
                  limit: 5,
                  required: false,
                  label: 'Industry',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
                {
                  selection_list: ['role_k50'],
                  limit: 5,
                  required: false,
                  label: 'Occupation',
                  description: '',
                  __typename: 'ReportValidationEntity',
                },
              ],
              __typename: 'ReportValidation',
            },
            __typename: 'ReportsValidationResponse',
          },
        ],
      },
    });
  });
};

export const createReportsHandler = () => {
  return graphql.mutation('createReports', (resolver) => {
    return HttpResponse.json({
      data: {
        createReports: {
          user_id: 'test_user_id',
          report_id: 'test_report_id',
          __typename: 'CreateReportsResponse',
        },
      },
    });
  });
};
