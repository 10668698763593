import { Environment } from './environment';

export const environment: Environment = {
  production: false,
  proxy: false,
  mixpanelToken: 'ec1f0aa55be261bfdd4532f606dcc86c',
  REACT_APP_URL: 'https://dashboard-dev.reveliolabs.com',
  REACT_APP_API: 'https://dashboard.dev.cloud.reveliolabs.com/proxy',
  ACCOUNT_PORTAL_ROOT: 'https://account-dev.reveliolabs.com',
  ACCOUNT_PORTAL_BACKEND_ROOT:
    'https://account-portal-dev.dev.cl.ue2.d01.aws.cloud.reveliolabs.com',
  DASH_META_API: 'https://dash-meta-dev.dev.cloud.reveliolabs.com',
  GO_API_ROOT: 'https://dashboard.dev.cloud.reveliolabs.com',
  ORY_KRATOS_URL: 'https://kratos.dev.cloud.reveliolabs.com',
  COMPANY_MAPPER_URL:
    'https://dev-comp-map.dev.cl.ue2.d01.aws.cloud.reveliolabs.com',
  COMPANY_MAPPER_SNOWFLAKE_URL:
    'https://dev-company-map-sfw.dev.cl.ue2.d01.aws.cloud.reveliolabs.com',
  OPEN_REPLAY_PROJECT_KEY: '',
  DOWNLOAD_DELIVERY_FILE_PREFIX: 'dev',
  UNLEASH_CLIENT_KEY:
    '*:development.2a8219f0ce918268f3c56ab1305d44e48dbfd06091910ef34f2c1fcc',
  UNLEASH_URL: 'https://us.app.unleash-hosted.com/usdd0003/api/frontend',
  SLOTS_API_KEY: 'iwveaelahjbtmvaokizrseemcwnoualx',
};
