import { LoaderType, PagePaths, PageTitles } from '@revelio/core';
import DashboardPage from '../../DashboardPage';
import { Box, Flex, Heading, Stack, StackDivider } from '@chakra-ui/layout';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
} from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { ReactNode, useEffect, useMemo } from 'react';
import { CircleIcon } from '@revelio/layout';

interface IStep {
  id: string;
  title: string;
  path: ReportBuilderDeliverablePaths;
  description?: string | ReactNode;
}

export enum ReportBuilderDeliverablePaths {
  REPORT_TYPE = 'report_type',
  ENTITY = 'entity',
  CONFIRMATION = 'confirmation',
}

export const steps: IStep[] = [
  {
    id: 'report_type',
    title: 'Report type',
    path: ReportBuilderDeliverablePaths.REPORT_TYPE,
  },
  {
    id: 'entity',
    title: 'Entity',
    path: ReportBuilderDeliverablePaths.ENTITY,
  },
  {
    id: 'confirmation',
    title: 'Confirmation',
    path: ReportBuilderDeliverablePaths.CONFIRMATION,
  },
];

export const ReportBuilderPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentStepIndex = useMemo(
    () => steps.findIndex((step) => location.pathname.includes(step.path)),
    [location]
  );
  useEffect(() => {
    if (location.pathname.endsWith(PagePaths.REPORT_BUILDER)) {
      navigate(ReportBuilderDeliverablePaths.REPORT_TYPE);
    }

    const deliverablePath = location.pathname.split('/').pop() as string;
    if (
      Object.values(ReportBuilderDeliverablePaths).includes(
        deliverablePath as ReportBuilderDeliverablePaths
      )
    ) {
      const currentStepIndex = steps.findIndex(
        (s) => s.path === deliverablePath
      );
      for (let i = currentStepIndex; i >= 0; i--) {
        navigate(steps[i].path);
        return;
      }
    }
  }, [navigate, location.pathname]);

  return (
    <DashboardPage
      title={[PageTitles.REPORT_BUILDER]}
      loader={LoaderType.MANUAL}
    >
      <Box rounded="lg" h="calc(100vh - 100px)">
        <Stack
          h="100%"
          direction="row"
          divider={<StackDivider width="15px" backgroundColor="gray.100" />}
          spacing="0"
          css={{
            '> div:first-of-type, > div:last-child': {
              background: 'white',
              borderRadius: '0.5rem',
            },
          }}
        >
          <Flex
            width="240px"
            height="100%"
            padding="32px 24px 16px"
            flexDirection="column"
            justifyContent="space-between"
            rounded="lg"
          >
            <Stepper
              colorScheme="green"
              index={currentStepIndex}
              orientation="vertical"
              height="500px"
              maxH="500px"
              gap="0"
              size="md"
            >
              {steps.map((step, index) => (
                <Step key={`dlStep-${index}`}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<CircleIcon boxSize="12" color="gray.200" />}
                      active={
                        <CircleIcon
                          boxSize="7"
                          color="green.500"
                          data-testid={`deliverable_${step.id}_step_active_icon`}
                        />
                      }
                    />
                  </StepIndicator>

                  <Box>
                    <StepTitle as="span">
                      <Heading
                        as="h1"
                        pt="6px"
                        pl="6px"
                        color="text.primary"
                        fontWeight="600"
                        fontSize="md"
                      >
                        {step.title}
                      </Heading>
                    </StepTitle>
                    {step.description &&
                    typeof step.description === 'string' ? (
                      <Box pl="6px">
                        <StepDescription>{step.description}</StepDescription>
                      </Box>
                    ) : (
                      <Text as="span" fontSize="2xs" color="text.primary">
                        {step.description}
                      </Text>
                    )}
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </Flex>
          <Outlet />
        </Stack>
      </Box>
    </DashboardPage>
  );
};
